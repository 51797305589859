import React, { useState } from 'react';
import axios from 'axios';
import { FaFileUpload, FaLink, FaYoutube } from 'react-icons/fa';
import { auth } from '../firebase'; // Import the auth instance

const AttachmentModal = ({ onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
    setLoading(true);

    if (websiteUrl) {
        const response = await fetch('https://app.studentaitools.com/api/get-title', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}` // Pass Firebase token to backend
            },
            body: JSON.stringify({
                type: 'url', 
                url: websiteUrl
            }),
        });
        const data = await response.json();
      onUpload({ type: 'url', title: data.title, url: websiteUrl });
    } else if (youtubeUrl) {
        const response = await fetch('https://app.studentaitools.com/api/get-title', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}` // Pass Firebase token to backend
            },
            body: JSON.stringify({
                type: 'youtube', 
                url: youtubeUrl
            }),
        });
        const data = await response.json();
      onUpload({ type: 'youtube', title: data.title, url: youtubeUrl });
    } else if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://app.studentaitools.com/api/upload-file', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${idToken}` // Pass Firebase token to backend
            },
            body: formData
        });
        const data = await response.json();
      onUpload({ type: 'file', title: selectedFile.name, url: data.url });

    }
    setLoading(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-96 p-4 rounded-lg shadow-lg relative">
        <button onClick={onClose} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h3 className="text-xl font-semibold mb-4">Attach File or Link</h3>

        {/* File Upload */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Upload File</label>
          <div className="flex items-center">
            <FaFileUpload className="text-gray-500 mr-2" />
            <input type="file" accept=".pdf, image/jpeg, image/png, image/tiff" onChange={handleFileChange} className="file-input file-input-bordered file-input-sm w-full max-w-xs" />
          </div>
        </div>

        {/* Website URL */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Enter Website URL</label>
          <div className="flex items-center">
            <FaLink className="text-gray-500 mr-2" />
            <input
              type="url"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              placeholder="https://example.com"
              className="w-full px-2 py-1 border rounded-lg"
            />
          </div>
        </div>

        {/* YouTube URL */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Enter YouTube URL</label>
          <div className="flex items-center">
            <FaYoutube className="text-red-500 mr-2" />
            <input
              type="url"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
              placeholder="https://youtube.com/example"
              className="w-full px-2 py-1 border rounded-lg"
            />
          </div>
        </div>

        {/* Upload Button */}
        <div className="mt-4 text-right">
          <button onClick={handleUpload} className="btn bg-green-600 text-white rounded-lg hover:bg-green-700">
          {loading && <span class="loading loading-spinner"></span>}
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttachmentModal;
